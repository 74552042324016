export const generateRotoramImage = (model, traySize) => {
  switch (model) {
    case "s-mega":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631995/Configurador-Rotoram/Finais/new/Rotoram_2021_s-mega_fgyjya.svg"

    case "single":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631994/Configurador-Rotoram/Finais/new/Rotoram_2021_single_vminre.svg"

    case "small":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631994/Configurador-Rotoram/Finais/new/Rotoram_2021_small_cnvwx3.svg"

    case "s-plus":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631996/Configurador-Rotoram/Finais/new/Rotoram_2021_s-plus_zvbg27.svg"

    case "s-maxi":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631994/Configurador-Rotoram/Finais/new/Rotoram_2021_s-maxi_yxpac6.svg"

    case "dual":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631992/Configurador-Rotoram/Finais/new/Rotoram_2021_dual_cxjz8l.svg"

    case "d-maxi":
      return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631992/Configurador-Rotoram/Finais/new/Rotoram_2021_d-maxi_q5tn7t.svg"

    case "mini": {
      const tray = traySize.replace(/^\D+/g, "")
      switch (tray) {
        case "40x60":
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631992/Configurador-Rotoram/Finais/new/Rotoram_2021_mini_T60x40_c8csu5.svg"

        case "50x70":
        case "45x75":
        case "58x69":
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622631994/Configurador-Rotoram/Finais/new/Rotoram_2021_mini_T5070_4575_5869_kmroc7.svg"
      }
    }
  }
}
