import React, { useState } from "react"
import Layout from "../layout"
import SEO from "../components/SEO"
import Rotoram from "../components/Configuradores/inputs/rotoram.json"
import { generateRotoramImage } from "../components/Configuradores/imageUtils/rotoram"
import { Configurador } from "../components/Configuradores/Configurador"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const ConfigRotoram = ({ location }) => {
  const { t } = useTranslation()

  const configuradorForm = t("configurador", { returnObjects: true })
  const userForm = t("form", { returnObjects: true })

  const [finalImg, setFinalImg] = useState("")

  const handleFinalImg = inputs => {
    const model = document.getElementById("modelo").value.toLowerCase()
    let traySize = inputs["dimensao-tabuleiros"]

    setFinalImg(generateRotoramImage(model, traySize))
  }

  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Rotoram" bodyClass={"configurador"}
        domain={location?.hostname}></SEO>
      <Configurador
        inputsJson={Rotoram}
        configuradorForm={configuradorForm}
        userForm={userForm}
        email={configuradorForm["email"]}
        oven={"Rotoram"}
        finalImg={finalImg}
        handleFinalImg={handleFinalImg}
      />
    </Layout>
  )
}

export default ConfigRotoram

export const ConfiguradorRotoram = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["rotoram", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
